import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axios";
import LoadingBar from "../../components/LoadingBar";
import BtnLoading from "../../components/BtnLoading";

const AddOption = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [productName, setProductName] = useState("");
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({
    size_id: "",
    color_id: "",
    stock: 0,
  });
  const [error, setError] = useState(""); // حالة لتخزين الأخطاء

  useEffect(() => {
    const fetchData = async () => {
      const [sizesResponse, colorsResponse, productResponse] =
        await Promise.all([
          axios.get("/sizes"),
          axios.get("/colors"),
          axios.get(`/products/${productId}`),
        ]);

      setSizes(sizesResponse.data);
      setColors(colorsResponse.data);
      setProductName(productResponse.data.product.name);
      setLoading(false);
    };

    fetchData();
  }, [productId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    if (!productId) return setError("Product ID is missing!");

    try {
      await axios.post(`/products/${productId}/options`, form);
      alert("Option added successfully");
      navigate(`/products/${productId}/options/`);
    } catch (error) {
      console.error("Error adding option:", error);
      setError(
        "فشل اضافة المنتج كلم المطور او ممكن انت بتضيف خصائص كانت متضافة قبل كده"
      );
    } finally {
      setDisabled(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  if (loading) {
    return <LoadingBar />;
  }

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          <strong className="font-bold">خطأ </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <h2 className="text-2xl font-bold mb-4">
        اضافة خيار لمنتج : {productName}
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="size_id"
            className="block text-sm font-medium text-gray-700"
          >
            المقاس
          </label>
          <select
            id="size_id"
            name="size_id"
            onChange={handleChange}
            value={form.size_id}
            required
            disabled={disabled}
            style={{
              padding: "0.5rem",
              backgroundColor: "#f8fafc",
              border: "1px solid #e2e8f0",
              borderRadius: "0.25rem",
            }}
          >
            <option value="">Select Size</option>
            {sizes.map((size) => (
              <option key={size.id} value={size.id}>
                {size.size_name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label
            htmlFor="color_id"
            className="block text-sm font-medium text-gray-700"
          >
            اللون
          </label>
          <select
            id="color_id"
            name="color_id"
            onChange={handleChange}
            value={form.color_id}
            required
            disabled={disabled}
            style={{
              padding: "0.5rem",
              backgroundColor: "#f8fafc",
              border: "1px solid #e2e8f0",
              borderRadius: "0.25rem",
            }}
          >
            <option value="">Select Color</option>
            {colors.map((color) => (
              <option key={color.id} value={color.id}>
                {color.color_name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label
            htmlFor="stock"
            className="block text-sm font-medium text-gray-700"
          >
            Stock
          </label>
          <input
            id="stock"
            type="number"
            name="stock"
            value={form.stock}
            onChange={handleChange}
            placeholder="Enter stock quantity"
            required
            disabled={disabled}
            className="mt-1 block p-2 w-fit border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            style={{ backgroundColor: "#f8fafc", border: "1px solid #e2e8f0" }}
          />
        </div>

        <button
          type="submit"
          disabled={disabled}
          className="mt-4 disableBtn bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          اضافة
          {disabled && <BtnLoading />}
        </button>
      </form>
    </div>
  );
};

export default AddOption;
