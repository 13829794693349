// src/pages/OffersPage.jsx
import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { Link, useParams } from "react-router-dom";
import LoadingBar from "../../components/LoadingBar";
import BtnLoading from "../../components/BtnLoading";

const OffersPage = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const { productId } = useParams();
  useEffect(() => {
    axios
      .get(`/offers/${productId}`)
      .then((response) => {
        setOffers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [productId]);

  const handleDelete = (offerId) => {
    setDisabled(true)
    axios
      .delete(`/offers/${offerId}`)
      .then(() => {
        setOffers(offers.filter((offer) => offer.id !== offerId));
      })
      .catch((error) => console.error(error));
      setDisabled(false)
  };

  return (
    <div className="px-5 mt-5">
      <h1 className="text-2xl font-bold mb-4">
        ادارة العروض الخاصة بالمنتج رقم {productId}
      </h1>
      <Link
        to={`/products/${productId}/offers/add`}
        className="bg-blue-500 text-white px-4 py-2 rounded mb-4 inline-block"
      >
        اضافة عرض جديد
      </Link>
      {loading ? (
        <LoadingBar />
      ) : (
        <table className="min-w-full bg-white border border-gray-200 text-right">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">كمية العرض</th>
              <th className="py-2 px-4 border-b">سعر العرض</th>
              <th className="py-2 px-4 border-b">التحكم</th>
            </tr>
          </thead>
          <tbody>
            {offers.map((offer) => (
              <tr key={offer.id}>
                <td className="py-2 px-4 border-b">{offer.quantity} قطعة</td>
                <td className="py-2 px-4 border-b">{offer.price} جنيه</td>
                <td className="py-2 px-4 border-b flex gap-5">
                  <Link
                    to={`/products/${productId}/offers/edit/${offer.id}`}
                    className="bg-blue-500 px-4 py-1 rounded text-white"
                  >
                    تعديل
                  </Link>
                  <button
                    disabled={disabled}
                    onClick={() => handleDelete(offer.id)}
                    className="bg-red-500 px-4 py-1 rounded text-white disableBtn"
                  >
                    حذف
                    {disabled && <BtnLoading />}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default OffersPage;
