// src/components/Navbar.js
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const Navbar = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("authToken"); // أو طريقة أخرى لحذف التوكن
    navigate("/login");
    window.location.reload(); // لإجبار إعادة تحميل الصفحة بعد تسجيل الخروج
  };

  if (!isAuthenticated) {
    return null; // إذا كان المستخدم غير مسجل دخول، لا تظهر الـ Navbar
  }

  return (
    <nav className="bg-gray-800 p-4 text-white">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex gap-5">
          <Link to="/products">ادارة المنتجات</Link>
          <Link to="/orders">ادارة الطلبات</Link>
          <Link to="/colors">ادارة الالوان</Link>
          <Link to="/sizes">ادارة المقاسات</Link>
        </div>
        <div>
          <button
            onClick={handleLogout}
            className="bg-red-500 px-3 py-1 rounded hover:bg-red-600"
          >
            تسجيل الخروج
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
