import axios from 'axios';

// تعيين الـ baseURL هنا
axios.defaults.baseURL = 'https://api.van-graph.com/api/admin';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// إضافة interceptor لإرفاق التوكن مع الطلبات
axios.interceptors.request.use(
  (config) => {
    // استثناء طلب تسجيل الدخول من إرسال التوكن
    if (!config.url.includes('/login')) {
      const authToken = localStorage.getItem('authToken'); // جلب التوكن من Local Storage أو من أي مكان آخر

      if (authToken) {
        config.headers['Authorization'] = `Bearer ${authToken}`; // إضافة التوكن إلى الهيدر
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
