import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { Link } from "react-router-dom";
import LoadingBar from "../../components/LoadingBar";

const SizesPage = () => {
  const [sizes, setSizes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/sizes")
      .then((response) => {
        setSizes(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-8">
        <h1 className="text-2xl font-bold mb-4 text-gray-800">إدارة المقاسات</h1>
        <Link
          to="/sizes/add"
          className="inline-block mb-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
        >
          إضافة مقاس جديد
        </Link>
        {loading ? (
          <LoadingBar />
        ) : (
          <table className="w-full border-collapse bg-white shadow-md rounded-md">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 text-right text-gray-600">الاسم</th>
                <th className="px-6 py-3 text-right text-gray-600">التحكم</th>
              </tr>
            </thead>
            <tbody>
              {sizes.map((size) => (
                <tr key={size.id} className="border-t">
                  <td className="px-6 py-4">{size.size_name}</td>
                  <td className="px-6 py-4">
                    <Link
                      to={`/sizes/edit/${size.id}`}
                      className="text-blue-500 hover:underline"
                    >
                      تعديل
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SizesPage;
