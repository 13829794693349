import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const EditOrder = () => {
  const { id } = useParams();
  const [orderData, setOrderData] = useState({
    customer_name: "",
    alternate_phone: "",
    detailed_address: "",
    customer_phone: "",
    status: "قيد المراجعة",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    // جلب بيانات الطلب
    axios
      .get(`orders/${id}`)
      .then((response) => {
        setOrderData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError("حدث خطأ في جلب بيانات الطلب");
        setLoading(false);
      });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrderData({ ...orderData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // تحديث الطلب
    axios
      .put(`/orders/${id}`, orderData)
      .then((response) => {
        alert("تم تعديل الطلب بنجاح");
      })
      .catch((error) => {
        setError("حدث خطأ أثناء تعديل الطلب");
      });
  };

  if (loading) return <div>جاري تحميل البيانات...</div>;

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">تعديل الطلب</h1>

      {error && <div className="text-red-500 mb-4">{error}</div>}

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            اسم العميل
          </label>
          <input
            type="text"
            name="customer_name"
            value={orderData.customer_name}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            هاتف العميل
          </label>
          <input
            type="text"
            name="customer_phone"
            value={orderData.customer_phone}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            رقم الهاتف الاحتياطي
          </label>
          <input
            type="number"
            name="alternate_phone"
            value={orderData.alternate_phone ? orderData.alternate_phone : ""}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            العنوان تفصيلي
          </label>
          <input
            type="text"
            name="detailed_address"
            value={orderData.detailed_address}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            حالة الطلب
          </label>
          <select
            name="status"
            value={orderData.status}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="قيد المراجعة">قيد المراجعة</option>
            <option value="في انتظار التسلم">في انتظار التسلم</option>
            <option value="تم التسليم">تم التسليم</option>
            <option value="تم الالغاء">تم الإلغاء</option>
          </select>
        </div>

        <button
          type="submit"
          className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
        >
          حفظ التعديلات
        </button>
      </form>
    </div>
  );
};

export default EditOrder;
