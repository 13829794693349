import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { Link, useParams } from "react-router-dom";
import LoadingBar from "../../components/LoadingBar"; // استيراد شريط التحميل
import BtnLoading from "../../components/BtnLoading";

const ProductOptionsPage = () => {
  const { productId } = useParams();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true); // حالة التحميل
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setLoading(true); // ابدأ التحميل
    axios
      .get(`/products/${productId}/options`)
      .then((response) => {
        setOptions(response.data);
        setLoading(false); // أوقف التحميل بعد الحصول على البيانات
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // أوقف التحميل حتى في حال وجود خطأ
      });
  }, [productId]);

  const handleDelete = async (optionId) => {
    if (window.confirm("هل أنت متأكد من حذف هذا الخيار؟")) {
      setDisabled(true);
      try {
        await axios.delete(`/options/${optionId}`);
        setOptions(options.filter((option) => option.id !== optionId));
      } catch (error) {
        console.error("Error deleting product option:", error);
        alert("حدث خطأ أثناء حذف الخيار.");
      } finally {
        setDisabled(false);
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
      <div className="flex mb-5 flex-wrap justify-between items-center">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">
          خصائص المنتج رقم : {productId}
        </h1>
        <Link
          to={`/products/${productId}/options/add`}
          className=" p-2 bg-gray-800 text-center rounded text-white"
        >
          اضف لون ومقاس
        </Link>
      </div>

      {loading ? (
        <LoadingBar /> // عرض شريط التحميل أثناء جلب البيانات
      ) : options.length === 0 ? (
        <p className="text-lg text-gray-500">
          لا توجد خيارات متاحه لهذا المنتج
        </p>
      ) : (
        <ul className="divide-y divide-gray-200">
          {options.map((option) => (
            <li
              key={option.id}
              className="py-4 flex justify-between items-center border-b border-gray-200"
            >
              <div className="flex flex-col">
                <span className="text-lg font-semibold">
                  المقاس: {option.size.size_name}
                </span>
                <span className="font-medium text-gray-600">
                  اللون : {option.color.color_name}
                </span>
                <span className="text-gray-900 font-medium">
                  المخزون: {option.stock}
                </span>
              </div>
              <div className="flex gap-3">
                <Link
                  to={`/products/${productId}/options/edit/${option.id}`}
                  className="bg-blue-500 px-4 py-2 rounded text-white"
                  disabled={disabled}
                >
                  تعديل
                </Link>
                <button
                  className="bg-red-500 px-4 py-2 rounded text-white disableBtn"
                  onClick={() => handleDelete(option.id)}
                  disabled={disabled}
                >
                  حذف
                  {disabled && <BtnLoading />}
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProductOptionsPage;
