import React, { useState } from "react";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";

const AddColorPage = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/colors", { color_name: name })
      .then(() => navigate("/colors"))
      .catch((error) => console.error(error));
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="max-w-md w-full bg-white shadow-md rounded-lg p-8">
        <h1 className="text-2xl font-bold mb-5 text-gray-800">إضافة لون</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="colorName"
              className="block text-gray-700 text-sm font-medium mb-2"
            >
              اسم اللون :
            </label>
            <input
              id="colorName"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            إضافة
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddColorPage;
