// src/App.js
import React, { useContext } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AuthContext, AuthProvider } from "./context/AuthContext";

import LoginPage from "./pages/LoginPage";
import LoadingPage from "./components/LoadingPage";
import Navbar from "./components/Navbar";
import AddProductPage from "./pages/Products/AddProductPage";
import ProductsPage from "./pages/Products/ProductsPage";
import EditProductPage from "./pages/Products/EditProductPage";
import OffersPage from "./pages/Offers/OffersPage";
import AddOfferPage from "./pages/Offers/AddOfferPage";
import EditOfferPage from "./pages/Offers/EditOfferPage";
import ProductOptionsPage from "./pages/Products/ProductOptionsPage";
import AddOption from "./pages/Options/AddOption";
import EditOption from "./pages/Options/EditOption";
import SizesPage from "./pages/Sizes/SizesPage";
import AddSizePage from "./pages/Sizes/AddSizePage";
import EditSizePage from "./pages/Sizes/EditSizePage";
import ColorsPage from "./pages/Colors/ColorsPage";
import AddColorPage from "./pages/Colors/AddColorPage";
import EditColorPage from "./pages/Colors/EditColorPage";
import OrdersPage from "./pages/Orders/OrdersPage";
import ProductImagesPage from "./pages/Products/ProductImagesPage";
import EditOrderPage from "./pages/Orders/EditOrderPage";
import OrderDetailsPage from "./pages/Orders/OrderDetailsPage";
import NotFoundPage from "./pages/NotFoundPage";

// تحسين مكون PrivateRoute
const PrivateRoute = ({ element }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    return <LoadingPage />;
  }

  return isAuthenticated ? element : <Navigate to="/login" />;
};

const App = () => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Router>
      <Navbar /> {/* إضافة Navbar هنا */}
      <Routes>
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/orders" /> : <LoginPage />}
        />
        <Route path="/" element={<Navigate to="/products" />} />
        {/* PRODUCTS ROUTES */}
        <Route
          path="/products"
          element={<PrivateRoute element={<ProductsPage />} />}
        />
        <Route
          path="/products/add"
          element={<PrivateRoute element={<AddProductPage />} />}
        />
        <Route
          path="/products/edit/:id"
          element={<PrivateRoute element={<EditProductPage />} />}
        />
        <Route
          path="/products/images/:id"
          element={
            <PrivateRoute
              element={<PrivateRoute element={<ProductImagesPage />} />}
            />
          }
        />
        {/* PRODUCT OFFERS ROUTES */}
        <Route
          path="/products/:productId/offers"
          element={<PrivateRoute element={<OffersPage />} />}
        />
        <Route
          path="/products/:productId/offers/add"
          element={<PrivateRoute element={<AddOfferPage />} />}
        />
        <Route
          path="/products/:productId/offers/edit/:offerId"
          element={<PrivateRoute element={<EditOfferPage />} />}
        />
        {/* PRODUCT OPTIONS */}
        <Route
          path="/products/:productId/options"
          element={<PrivateRoute element={<ProductOptionsPage />} />}
        />
        <Route
          path="/products/:productId/options/add"
          element={<PrivateRoute element={<AddOption />} />}
        />
        <Route
          path="/products/:productId/options/edit/:optionId"
          element={<PrivateRoute element={<EditOption />} />}
        />
        {/* SIZE ROUTES */}
        <Route
          path="/sizes"
          element={<PrivateRoute element={<SizesPage />} />}
        />
        <Route
          path="/sizes/add"
          element={<PrivateRoute element={<AddSizePage />} />}
        />
        <Route
          path="/sizes/edit/:id"
          element={<PrivateRoute element={<EditSizePage />} />}
        />
        {/* COLORS ROUTES */}
        <Route
          path="/colors"
          element={<PrivateRoute element={<ColorsPage />} />}
        />
        <Route
          path="/colors/add"
          element={<PrivateRoute element={<AddColorPage />} />}
        />
        <Route
          path="/colors/edit/:id"
          element={<PrivateRoute element={<EditColorPage />} />}
        />
        {/* ORDERS ROUTES */}
        <Route
          path="/orders"
          element={<PrivateRoute element={<OrdersPage />} />}
        />
        <Route
          path="/orders/:id"
          element={<PrivateRoute element={<OrderDetailsPage />} />}
        />
        <Route
          path="/orders/:id/edit"
          element={<PrivateRoute element={<EditOrderPage />} />}
        />
        <Route path="*" element={<NotFoundPage />} /> {/* صفحة 404 */}
      </Routes>
    </Router>
  );
};

const MainApp = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default MainApp;
