import React from 'react';

const LoadingPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="flex items-center flex-col">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32 mb-7"></div>
        <h2 className="text-xl font-semibold mb-3">جاري التحميل....</h2>
        <p className="text-gray-500">برجاء الانتظار حتى يتم تحميل البيانات</p>
      </div>
    </div>
  );
};

export default LoadingPage;
