import React from "react";
import { useParams } from "react-router-dom";
import axios from "../../axios";

const OrderDetailsPage = () => {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = React.useState(null);

  React.useEffect(() => {
    axios
      .get(`/orders/${id}`)
      .then(({ data }) => setOrderDetails(data))
      .catch(() =>
        setOrderDetails({ error: "حدث خطأ أثناء جلب تفاصيل الطلب" })
      );
  }, [id]);

  if (!orderDetails) return <div>جاري تحميل تفاصيل الطلب...</div>;
  if (orderDetails.error) return <div>{orderDetails.error}</div>;

  const {
    customer_name,
    customer_phone,
    alternate_phone,
    governorate,
    detailed_address,
    created_at,
    updated_at,
    status,
    order_items,
  } = orderDetails;

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4 inline-block ml-4">
        تفاصيل الطلب #{orderDetails.id}
      </h1>
      <h1 className="text-2xl font-bold mb-4 inline-block">
        <strong>حالة الطلب:</strong> {status}
      </h1>
      <div className="flex flex-col flex-wrap gap-5 justify-around">
        <div className="bg-g border border-gray-300 rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-2 border-b pb-2 border-gray-600">
            بيانات العميل
          </h2>
          <p>
            <strong>اسم العميل:</strong> {customer_name}
          </p>
          <p>
            <strong>هاتف العميل:</strong> {customer_phone}
          </p>

          <p>
            <strong>هاتف بديل:</strong>{" "}
            {alternate_phone ? alternate_phone : "لا يوجد"}
          </p>
          <p>
            <strong>المحافظة:</strong> {governorate}
          </p>
          <p>
            <strong>العنوان التفصيلي:</strong> {detailed_address}
          </p>
          <p>
            <strong>تاريخ الطلب:</strong>{" "}
            {new Date(created_at).toLocaleDateString()}
          </p>
          <p>
            <strong>اخر تحديث للطلب:</strong>{" "}
            {new Date(updated_at).toLocaleDateString()}
          </p>

          <p>
            <strong>تكلفة الشحن:</strong> {orderDetails.shipping_cost} جنيه
          </p>
          <p>
            <strong>السعر الإجمالي:</strong> {orderDetails.total_price} جنيه
          </p>
        </div>

        <div className="bg-white border border-gray-300 rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-2 border-b pb-2 border-gray-600">
            تفاصيل المنتجات ({order_items.length} منتج)
          </h2>
          {order_items.map((item, index) => {
            const productItems = JSON.parse(item.items); // تحويل الحقل "items" إلى JSON
            return (
              <div key={item.id} className="mb-4 border-b pb-4 border-gray-800">
                <strong>المنتج {index + 1}</strong>
                <p>
                  <strong>اسم المنتج:</strong> {item.product.name}
                </p>
                <p>
                  <strong>العرض:</strong> {item.quantity} قطع بـ{" "}
                  {item.offer.price} جنيه
                </p>

                <div className="mt-2">
                  <h3 className="text-lg font-semibold mb-2">تفاصيل القطع:</h3>
                  {productItems.map((productItem, index) => (
                    <div key={index} className="ml-4">
                      <p>
                        <strong>القطعة {index + 1}</strong>
                        <br />
                        <strong>اللون :</strong>
                        <span>{productItem.color}</span>
                        <strong className="mr-2">المقاس :</strong>
                        <span>{productItem.size}</span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsPage;
