// src/components/LoginPage.js
import React, { useState } from "react";
import axios from "../axios";
import BtnLoading from "../components/BtnLoading";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);
    try {
      const response = await axios.post("/login", {
        username: username.trim(),
        password: password.trim(),
      });

      if (response.data.token) {
        localStorage.setItem("authToken", response.data.token);
        window.location = "/";
      } else {
        setError("اسم المستخدم أو كلمة المرور غير صحيحة");
      }
    } catch (error) {
      setError("حدث خطأ اثناء تسجيل الدخول!");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-80">
        <h2 className="text-2xl font-bold mb-4 text-center">تسجيل الدخول</h2>
        {error && (
          <p className="text-red-500 mb-4 m-auto text-center">{error}</p>
        )}
        <form onSubmit={handleLoginSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">اسم المستخدم</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 mb-2">كلمة المرور</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded"
              required
            />
          </div>
          <button
            type="submit"
            className={`w-full bg-blue-500 text-white py-2 rounded disableBtn`}
            disabled={disabled}
          >
            تسجيل الدخول
            {disabled && <BtnLoading />}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
