import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { useParams } from "react-router-dom";
import LoadingBar from "../../components/LoadingBar";
import BtnLoading from "../../components/BtnLoading";

const EditOption = () => {
  const [disabled, setDisabled] = useState(false);
  const { optionId } = useParams();
  const [option, setOption] = useState({
    size_id: "",
    color_id: "",
    stock: 0,
  });
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(true); // حالة التحميل
  const [error, setError] = useState(""); // حالة الأخطاء

  useEffect(() => {
    const fetchData = async () => {
      const [optionResponse, sizesResponse, colorsResponse] = await Promise.all(
        [
          axios.get(`/options/${optionId}`),
          axios.get("/sizes"),
          axios.get("/colors"),
        ]
      );

      setOption(optionResponse.data);
      setSizes(sizesResponse.data);
      setColors(colorsResponse.data);
      setLoading(false); // إنهاء حالة التحميل
    };

    fetchData();
  }, [optionId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOption((prevOption) => ({ ...prevOption, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true)
    try {
      await axios.put(`/options/${optionId}`, option);
      alert("تم تحديث الخيار بنجاح");
    } catch (error) {
      setError("Error updating option");
      console.error(error);
    } finally {
      setDisabled(false);
    }
  };

  if (loading) {
    return <LoadingBar />;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-8">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      <h2 className="text-2xl font-bold mb-6">تعديل خيار</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="size_id"
            className="block text-sm font-medium text-gray-700"
          >
            المقاس
          </label>
          <select
            id="size_id"
            name="size_id"
            value={option.size_id}
            disabled={disabled}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          >
            <option value="">Select Size</option>
            {sizes.map((size) => (
              <option key={size.id} value={size.id}>
                {size.size_name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label
            htmlFor="color_id"
            className="block text-sm font-medium text-gray-700"
          >
            اللون
          </label>
          <select
            id="color_id"
            name="color_id"
            value={option.color_id}
            disabled={disabled}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          >
            <option value="">Select Color</option>
            {colors.map((color) => (
              <option key={color.id} value={color.id}>
                {color.color_name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label
            htmlFor="stock"
            className="block text-sm font-medium text-gray-700"
          >
            Stock
          </label>
          <input
            id="stock"
            type="number"
            name="stock"
            value={option.stock}
            onChange={handleChange}
            disabled={disabled}
            placeholder="Enter stock quantity"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>

        <button
          disabled={disabled}
          type="submit"
          className="mt-4 w-full disableBtn bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          تحديث الخيار
          {disabled && <BtnLoading />}
        </button>
      </form>
    </div>
  );
};

export default EditOption;
