// src/pages/ProductsPage.jsx
import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { Link } from "react-router-dom";
import LoadingBar from "../../components/LoadingBar";
import BtnLoading from "../../components/BtnLoading";

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    fetchProducts();
  }, []);

  // جلب المنتجات من السيرفر
  const fetchProducts = () => {
    setLoading(true);
    axios
      .get("/products")
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  // وظيفة حذف المنتج
  const handleDelete = (id) => {
    if (window.confirm("هل أنت متأكد من حذف هذا المنتج؟")) {
      setDisabled(true); // إظهار التحميل أثناء الحذف
      axios
        .delete(`/products/${id}`)
        .then(() => {
          setProducts(products.filter((product) => product.id !== id));
        })
        .catch((error) => {
          console.error("Error deleting product:", error);
          alert("حدث خطأ أثناء حذف المنتج.");
        })
        .finally(() => {
          setDisabled(false); // إخفاء التحميل بعد الحذف
        });
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">إدارة المنتجات</h1>
      <Link
        to="/products/add"
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mb-4 inline-block"
      >
        إضافة منتج جديد
      </Link>
      {loading ? (
        <LoadingBar />
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="py-2 px-4 text-right text-gray-600">ID</th>
                <th className="py-2 px-4 text-right text-gray-600">
                  اسم المنتج
                </th>
                <th className="py-2 px-4 text-right text-gray-600">التحكم</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.id} className="border-b">
                  <td className="py-2 px-4">{product.id}</td>
                  <td className="py-2 px-4">{product.name}</td>
                  <td className="py-2 px-4 flex gap-4">
                    <Link
                      to={`/products/edit/${product.id}`}
                      className="text-right px-4 py-1 rounded bg-blue-600 hover:bg-blue-700 text-white"
                    >
                      تعديل
                    </Link>
                    <Link
                      to={`/products/${product.id}/options`}
                      className="text-right px-4 py-1 rounded bg-blue-600 hover:bg-blue-700 text-white"
                    >
                      ادارة الخصائص
                    </Link>
                    <Link
                      to={`/products/${product.id}/offers`}
                      className="text-right px-4 py-1 rounded bg-blue-600 hover:bg-blue-700 text-white"
                    >
                      ادارة العروض
                    </Link>
                    <Link
                      to={`/products/images/${product.id}`}
                      className="text-right px-4 py-1 rounded bg-blue-600 hover:bg-blue-700 text-white"
                    >
                      ادارة الصور
                    </Link>
                    {/* زر الحذف */}
                    <button
                      onClick={() => handleDelete(product.id)}
                      disabled={disabled}
                      className="px-4 py-1 rounded bg-red-600 hover:bg-red-700 text-white disableBtn"
                    >
                      حذف
                      {disabled && <BtnLoading />}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ProductsPage;
