import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { Link } from "react-router-dom";
import LoadingBar from "../../components/LoadingBar";

const ColorsPage = () => {
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/colors")
      .then((response) => {
        setColors(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-4">إدارة الألوان</h1>
        <Link
          to="/colors/add"
          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 mb-4 inline-block"
        >
          إضافة لون جديد
        </Link>
        {loading ? (
          <LoadingBar />
        ) : (
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-600">
                <th className="py-2 px-4 border-b">اسم اللون</th>
                <th className="py-2 px-4 border-b text-center">التحكم</th>
              </tr>
            </thead>
            <tbody>
              {colors.map((color) => (
                <tr key={color.id} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b text-center">{color.color_name}</td>
                  <td className="py-2 px-4 border-b text-center">
                    <Link
                      to={`/colors/edit/${color.id}`}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      تعديل
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ColorsPage;
