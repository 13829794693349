import React, { useState } from "react";
import axios from "../../axios"; // تأكد من تكوين axios بشكل صحيح
import BtnLoading from "../../components/BtnLoading";
import { useNavigate } from "react-router-dom";

const AddProduct = () => {
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    name: "",
    description: "",
    main_image: null,
    gallery: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    if (name === "main_image") {
      setForm((prevForm) => ({ ...prevForm, main_image: files[0] }));
    } else {
      setForm((prevForm) => ({ ...prevForm, gallery: Array.from(files) }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const formData = new FormData();
    formData.append("name", form.name);
    formData.append("description", form.description);
    formData.append("main_image", form.main_image);
    form.gallery.forEach((image, index) => formData.append(`gallery[]`, image));

    try {
      await axios.post("/products", formData);
      navigate("/products");
    } catch (error) {
      console.error("Error adding product:", error);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">اضافة منتج جديد</h1>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-gray-700 font-medium mb-2">
            اسم المنتج
          </label>
          <input
            type="text"
            name="name"
            value={form.name}
            onChange={handleChange}
            required
            disabled={disabled}
            className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">
            وصف المنتج
          </label>
          <textarea
            name="description"
            value={form.description}
            onChange={handleChange}
            required
            disabled={disabled}
            className="w-full border border-gray-300 p-2 rounded-md h-48 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">
            الصورة الرئيسية
          </label>
          <input
            type="file"
            name="main_image"
            onChange={handleImageChange}
            accept="image/*"
            required
            disabled={disabled}
            className="w-full text-gray-500 border border-gray-300 p-2 rounded-md file:mr-4 file:py-2 file:px-4 file:border-0 file:rounded-md file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">
            معرض الصور ( ينفع ترفع صور متعددة )
          </label>
          <input
            type="file"
            name="gallery"
            multiple
            onChange={handleImageChange}
            accept="image/*"
            disabled={disabled}
            className="w-full text-gray-500 border border-gray-300 p-2 rounded-md file:mr-4 file:py-2 file:px-4 file:border-0 file:rounded-md file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
        </div>

        <button
          disabled={disabled}
          type="submit"
          className="w-full disableBtn bg-blue-500 text-white py-2 px-4 rounded-md font-semibold hover:bg-blue-600 transition duration-300"
        >
          اضافة المنتج
          {disabled && <BtnLoading />}
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
