import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { useParams, useNavigate } from "react-router-dom";
import LoadingBar from "../../components/LoadingBar";
import BtnLoading from "../../components/BtnLoading";

const EditProductPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({ name: "", description: "" });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    axios.get(`/products/${id}`).then((response) => {
      setProduct(response.data.product);
      setLoading(false);
    });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    try {
      await axios.put(`/products/${id}`, product);
      alert("تم تحديث بيانات المنتج بنجاح.");
      navigate("/products");
    } catch (error) {
      console.error("Submit Error:", error);
      alert("حدث خطأ أثناء تحديث بيانات المنتج.");
    } finally {
      setDisabled(false);
    }
  };

  if (loading) return <LoadingBar />;

  return (
    <div className="container mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold mb-6">تعديل بيانات المنتج</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 gap-6">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            الاسم:
          </label>
          <input
            id="name"
            type="text"
            name="name"
            value={product.name}
            onChange={handleChange}
            required
            disabled={disabled}
            className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-50 px-3 py-2 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="grid grid-cols-1 gap-6">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            الوصف:
          </label>
          <textarea
            id="description"
            name="description"
            value={product.description}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-50 px-3 py-2 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            rows="4"
            disabled={disabled}
            style={{ height: "450px" }}
          />
        </div>
        <button
          disabled={disabled}
          type="submit"
          className="bg-indigo-600 disableBtn text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          تحديث المنتج
          {disabled && <BtnLoading />}
        </button>
      </form>
    </div>
  );
};

export default EditProductPage;
