// src/pages/AddOfferPage.jsx
import React, { useState } from "react";
import axios from "../../axios";
import { useParams, useNavigate } from "react-router-dom";
import BtnLoading from "../../components/BtnLoading";

const AddOfferPage = () => {
  const { productId } = useParams();
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    try {
      await axios.post("/offers", { product_id: productId, quantity, price });
      navigate(`/products/${productId}/offers`);
    } catch (error) {
      alert("حدث خطأ اثناء اضافة العرض");
      console.error(error);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-8 bg-white rounded-lg shadow-lg mt-9">
      <h1 className="text-3xl font-bold mb-6">اضافة عرض جديد للمنتج</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-gray-700" htmlFor="quantity">
            عدد القطع
          </label>
          <input
            id="quantity"
            type="number"
            value={quantity}
            disabled={disabled}
            onChange={(e) => setQuantity(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700" htmlFor="price">
            سعر العرض
          </label>
          <input
            id="price"
            type="number"
            step="0.01"
            value={price}
            disabled={disabled}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <button
          disabled={disabled}
          type="submit"
          className="w-full disableBtn bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          اضافة
          {disabled && <BtnLoading />}
        </button>
      </form>
    </div>
  );
};

export default AddOfferPage;
