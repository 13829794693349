import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "../../axios";
import { useParams, useNavigate } from "react-router-dom";
import LoadingBar from "../../components/LoadingBar";

const ProductImagesPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [product, setProduct] = useState({
    main_image_url: "",
    gallery_urls: [],
    video_urls: [],
  });

  useEffect(() => {
    axios
      .get(`/products/${id}`)
      .then((response) => {
        const { main_image, gallery, videos } = response.data.product;
        setProduct({
          main_image_url: main_image,
          gallery_urls: gallery ? JSON.parse(gallery) : [],
          video_urls: videos ? JSON.parse(videos) : [],
        });
      })
      .catch((error) => console.error("Error fetching product data:", error))
      .finally(() => setLoading(false));
  }, [id]);

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'video/*': ['.mp4', '.mov', '.ogg']
    },
    multiple: true,
    onDrop: (acceptedFiles) => handleUpload(acceptedFiles),
  });

  const handleUpload = async (files) => {
    const formData = new FormData();
    files.forEach((file) => {
      if (file.type.startsWith('image')) {
        formData.append('gallery[]', file);
      } else if (file.type.startsWith('video')) {
        formData.append('videos[]', file);
      }
    });

    try {
      await axios.post(`/products/${id}/upload-media`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("تم تحميل الصور والفيديوهات بنجاح.");
      fetchProductData();
    } catch (error) {
      console.error("Error uploading media:", error);
      alert("حدث خطأ أثناء تحميل الوسائط.");
    }
  };

  const handleDeleteGalleryImage = async (url) => {
    if (window.confirm("هل أنت متأكد من حذف هذه الصورة؟")) {
      try {
        await axios.delete(`/products/${id}/gallery`, {
          data: { image_path: url },
        });
        alert("تم حذف الصورة بنجاح.");
        fetchProductData();
      } catch (error) {
        console.error("Error deleting image:", error);
        alert("حدث خطأ أثناء حذف الصورة.");
      }
    }
  };

  const handleDeleteVideo = async (url) => {
    if (window.confirm("هل أنت متأكد من حذف هذا الفيديو؟")) {
      try {
        await axios.delete(`/products/${id}/videos`, {
          data: { video_path: url },
        });
        alert("تم حذف الفيديو بنجاح.");
        fetchProductData();
      } catch (error) {
        console.error("Error deleting video:", error);
        alert("حدث خطأ أثناء حذف الفيديو.");
      }
    }
  };

  const fetchProductData = () => {
    axios
      .get(`/products/${id}`)
      .then((response) => {
        const { main_image, gallery, videos } = response.data.product;
        setProduct({
          main_image_url: main_image,
          gallery_urls: gallery ? JSON.parse(gallery) : [],
          video_urls: videos ? JSON.parse(videos) : [],
        });
      })
      .catch((error) => console.error("Error fetching product data:", error));
  };

  return (
    <div className="container mx-auto p-6 bg-white shadow-lg rounded-lg">
      {loading && <LoadingBar />}
      <h1 className="text-3xl font-bold mb-6">إدارة صور وفيديوهات المنتج</h1>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          الصورة الرئيسية الحالية:
        </label>
        {product.main_image_url && (
          <div className="mt-2">
            <img
              src={`https://api.van-graph.com/storage/${product.main_image_url}`}
              alt="Main"
              className="h-40 w-40 object-cover mb-2"
            />
          </div>
        )}
      </div>

      <div className="mt-6">
        <label className="block text-sm font-medium text-gray-700">
          صور المعرض الحالية:
        </label>
        <div className="mt-2 grid grid-cols-4 gap-4">
          {product.gallery_urls.map((url, index) => (
            <div key={index} className="relative">
              <img
                src={`https://api.van-graph.com/storage/${url}`}
                alt={`Gallery ${index}`}
                className="h-24 w-24 object-cover"
              />
              <button
                type="button"
                className="absolute top-0 right-0 bg-red-600 text-white rounded-full p-1"
                onClick={() => handleDeleteGalleryImage(url)}
              >
                X
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-6">
        <label className="block text-sm font-medium text-gray-700">
          فيديوهات المنتج الحالية:
        </label>
        <div className="mt-2 grid grid-cols-4 gap-4">
          {product.video_urls.map((url, index) => (
            <div key={index} className="relative">
              <video controls className="h-24 w-24">
                <source src={`http://localhost:8000/storage/${url}`} type="video/mp4" />
              </video>
              <button
                type="button"
                className="absolute top-0 right-0 bg-red-600 text-white rounded-full p-1"
                onClick={() => handleDeleteVideo(url)}
              >
                X
              </button>
            </div>
          ))}
        </div>
      </div>

      <div {...getRootProps({ className: "mt-6" })}>
        <input {...getInputProps()} />
        <button
          type="button"
          className="px-4 py-2 bg-blue-600 text-white rounded-md"
        >
          إضافة صور أو فيديوهات
        </button>
      </div>
    </div>
  );
};

export default ProductImagesPage;
