import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchOrders = async () => {
      setProgress(30);
      try {
        const response = await axios.get("/orders");
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
      setProgress(100);
      setLoading(false);
    };

    fetchOrders();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("هل أنت متأكد من حذف هذا الطلب؟")) {
      try {
        await axios.delete(`/orders/${id}`);
        setOrders(orders.filter((order) => order.id !== id));
      } catch (error) {
        console.error("Error deleting order:", error);
      }
    }
  };

  const filteredOrders = orders.filter((order) => {
    return (
      order.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.id.toString().includes(searchQuery)
    );
  });

  if (loading) return <p>جاري تحميل الطلبات...</p>;

  return (
    <div className="container mx-auto p-6">
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <h1 className="text-3xl font-bold mb-6">قائمة الطلبات</h1>
      <div className="flex justify-between mb-6">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="بحث عن طلب"
          className="py-2 px-4 border border-gray-300 rounded"
        />
        <a
          href="https://api.van-graph.com/orders/pdf"
          className="bg-green-500 text-white py-2 px-4 rounded"
        >
          طباعة الطلبات
        </a>
      </div>
      <table className="min-w-full bg-white">
        <thead>
          <tr className="w-full bg-gray-100 text-right">
            <th className="py-2 px-4">رقم الطلب</th>
            <th className="py-2 px-4">اسم العميل</th>
            <th className="py-2 px-4">المحافظة</th>
            <th className="py-2 px-4">السعر الكلي</th>
            <th className="py-2 px-4">الشحن</th>
            <th className="py-2 px-4">حالة الطلب</th>
            <th className="py-2 px-4">خيارات</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order) => (
            <tr key={order.id} className="border-b border-gray-200">
              <td className="py-2 px-4">{order.id}</td>
              <td className="py-2 px-4">{order.customer_name}</td>
              <td className="py-2 px-4">{order.governorate}</td>
              <td className="py-2 px-4">{order.total_price} جنيه</td>
              <td className="py-2 px-4">{order.shipping_cost} جنيه</td>
              <td
                className="py-2 px-4"
                style={{
                  backgroundColor:
                    order.status === "في انتظار التسلم"
                      ? "#E5E5EA"
                      : order.status === "قيد المراجعة"
                      ? "#F7DC6F"
                      : order.status === "تم التسليم"
                      ? "#22c55e"
                      : "",
                }}
              >
                {order.status}
              </td>
              <td className="py-2 px-4">
                <div className="flex gap-3">
                  <Link
                    to={`/orders/${order.id}`}
                    className="bg-blue-500 text-white py-1 px-2 rounded"
                  >
                    عرض
                  </Link>
                  <Link
                    to={`/orders/${order.id}/edit`}
                    className="bg-yellow-500 text-white py-1 px-2 rounded"
                  >
                    تعديل
                  </Link>
                  <button
                    onClick={() => handleDelete(order.id)}
                    className="bg-red-500 text-white py-1 px-2 rounded"
                  >
                    حذف
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrdersPage;
